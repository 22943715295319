<template>
  <div>
    <input type="file" id="files" name="files" multiple /><br /><br />
    <b-table striped hover :items="routeItems"></b-table>
    <ul id="myList"></ul>
    <template v-for="item in items">
      <b-nav-item :key="item.path" :to="item.path">
        {{ item.name }}
      </b-nav-item>
    </template>
    <RedirectErrorModal ref="RedirectErrorModal" @Accept="onRedirect" />
  </div>
</template>

<script>
const RedirectErrorModal = () => import("@/components/RedirectErrorModal");
export default {
  components: {
    RedirectErrorModal
  },
  data() {
    return {
      items: [],
      route: this.$route,
      routeItems: [
        { field: "fullPath", vaule: JSON.stringify(this.$route.fullPath) },
        { field: "hash", vaule: JSON.stringify(this.$route.hash) },
        // { field: "matched", vaule: JSON.stringify(this.$route.matched) },
        // { field: "meta", vaule: JSON.stringify(this.$route.meta) },
        // { field: "name", vaule: JSON.stringify(this.$route.name) },
        { field: "params", vaule: JSON.stringify(this.$route.params) },
        { field: "path", vaule: JSON.stringify(this.$route.path) },
        { field: "query", vaule: JSON.stringify(this.$route.query) }
      ]
    };
  },
  created() {
    this.$router.options.routes.forEach(route => {
      this.items.push({
        name: route.name,
        path: route.path
      });
    });
  },
  beforeCreate() {
    this.$liff.init({ liffId: "1654133345-JmNBe5p6" });
  },
  mounted() {
    console.log(this.$route);
    document.getElementById("files").addEventListener("change", function(e) {
      var node = document.createElement("li");
      var textnode = document.createTextNode(e.target.files.length);
      node.appendChild(textnode);
      document.getElementById("myList").appendChild(node);

      Array.prototype.forEach.call(e.target.files, element => {
        console.log(element);
        var node = document.createElement("li");
        var textnode = document.createTextNode(element.name);
        node.appendChild(textnode);
        document.getElementById("myList").appendChild(node);
      });
    });
    // setTimeout(() => {
    //   this.$bvModal.show("redirectErrorModal");
    // }, 1000);
  }
};
</script>

<style></style>
